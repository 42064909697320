<template>
    <div class="list center">
        <h2>{{ $t("Profile.notifications.transactional_email") }}</h2>
        <div>
            <p>{{ $t("Profile.notifications.project_generation") }}</p>
            <Switch v-model="video_generation" :leftChoice_display="$t('Profile.notifications.disable')" :rightChoice_display="$t('Profile.notifications.enable')" />
        </div>
        <div>
            <p>{{ $t("Profile.notifications.video_delivering") }}</p>
            <Switch v-model="video_delivered"  :leftChoice_display="$t('Profile.notifications.disable')" :rightChoice_display="$t('Profile.notifications.enable')" />
        </div>
    </div>
</template>

<script>
import axios from "axios";
import Vuex from "vuex";

export default {
  
    mounted(){
        this.temp_usr = this.getCurrentUser
    },
    computed: {
    ...Vuex.mapGetters(["getCurrentUsrId", "getCurrentUser"]),
    
    video_generation : {
        get(){
            return this.getCurrentUser.usr_notifications_preferences.video_generation
        },
        set(val){
            this.getCurrentUser.usr_notifications_preferences.video_generation = val
            this.updateUserNotificationsPreferences()
        }
    },
    video_delivered : {
        get(){
            return this.getCurrentUser.usr_notifications_preferences.video_delivered
        },
        set(val){
            this.getCurrentUser.usr_notifications_preferences.video_delivered = val
            this.updateUserNotificationsPreferences()
        }
    }
  },
  methods : {
    updateUserNotificationsPreferences(){
        return axios.put(`/users/${this.getCurrentUser._id}`, {
            usr_notifications_preferences : {
                video_generation : this.video_generation,
                video_delivered : this.video_delivered
            }
        })
        .catch(e=>{
            this.Toaster.error()
        })
    }
  }
}
</script>


<style scoped lang="scss">
.list{
    max-width: 1000px;
    width: 60%;
    min-width: 850px;

    & > div{
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-around;
        width: 100%;
        border: 0px var(--color_border) solid;
        border-top-width: 1px;
        padding-top: 10px;
        padding-bottom: 10px;

        &:last-child{
            border-bottom-width: 1px;
        }

        & > *:nth-child(1){
            width: 40%;
            text-align: left;
        }

        & > *:nth-child(2){
            width: 60%;
        }

        .switch{
            width: 400px
        }
    }
}


h2 {
    margin-left:10px;
    margin-bottom: 20px;
    text-align: center;
}
</style>